"use client";

import React from "react";

import Button from "@ng-components/actions/Button";

interface RefreshButtonProps {
    text: string;
}

function refresh() {
    window.location.reload();
}

export default function RefreshButton({text}: RefreshButtonProps) {
    return <Button onClick={refresh} text={text} />;
}
