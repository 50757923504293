"use client"; // Error components must be Client Components

import React from "react";

import BugSnagNotification from "@ng-components/error-page/BugSnagNotification";
import Actions from "@ng-components/actions/Actions";
import RefreshButton from "@ng-components/error-page/RefreshButton";
import ErrorPageAnimation from "@ng-components/error-page/ErrorPageAnimation";
import ErrorPageDescription from "@ng-components/error-page/ErrorPageDescription";
import ErrorPageSection from "@ng-components/error-page/ErrorPageSection";
import ErrorPageTitle from "@ng-components/error-page/ErrorPageTitle";

export default function GlobalError({error}: {error: Error}) {
    return (
        <html>
            <body>
                <BugSnagNotification error={error} errorBoundary="root-layout" />
                <ErrorPageSection>
                    <ErrorPageAnimation fileUrl="/assets/error-animation.mp4" />
                    <ErrorPageTitle>Le bateau prend l’eau...</ErrorPageTitle>
                    <ErrorPageDescription>
                        Un problème technique a eu lieu. Nos équipes sont sur le pont !
                    </ErrorPageDescription>
                    <Actions justify="center">
                        <RefreshButton text="Rafraîchir la page" />
                    </Actions>
                </ErrorPageSection>
            </body>
        </html>
    );
}
